@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles for summary content */
.summary-content a {
  color: rgb(37 99 235);
  text-decoration: none;
  margin-left: 0.15rem;
}

.summary-content a:hover {
  color: rgb(30 64 175);
}

.summary-content a + a {
  margin-left: 0;
}

/* Better spacing for citation links */
.summary-content {
  line-height: 1.5;
}

/* Ensure consistent font weight */
h1, h2, h3 {
  font-weight: normal;
}

h1 {
  font-weight: bold;
}